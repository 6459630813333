
import { defineComponent, ref, withKeys } from 'vue';

import Tabs from '@/components/tabs/index';

export default defineComponent({
  components: {
    Tabs,
  },
  props: {
    poName: {
      type: String,
      default: '',
    },
  },
  emits: ['update:poName', 'search', 'record'],
  setup(props, { emit }) {
    const tabList = ref([
      {
        label: 'INS',
        value: '0',
      },
    ]);
    const currentTab = ref('0');
    const handleInput = (v: string) => {
      emit('update:poName', v);
    };
    // 切换 Tab
    const handleTab = () => {
      // emit('change', currentTab.value);
    };

    const handleSearch = () => {
      emit('search');
    };

    const handleRecord = () => {
      emit('record');
    };

    return {
      tabList,
      currentTab,
      handleTab,
      handleSearch,
      handleInput,
      handleRecord,
    };
  },

  render() {
    return (
      <el-row class="tab-bar" justify="space-between" align="middle">
        <el-col span={8}>
          <tabs
            lineHeight={56}
            fontSize={16}
            border={false}
            config={this.tabList}
            v-model={this.currentTab}
            onChange={this.handleTab}
          />
        </el-col>
        <el-col span={8} class="flex">
          <el-input
            modelValue={this.poName}
            placeholder="请输入博主名称进行搜索"
            input-style={{
              paddingRight: '36px',
              backgroundColor: '#EFF0F5',
            }}
            onKeydown={withKeys(() => { this.handleSearch(); }, ['enter'])}
            v-slots={{
              suffix: () => (
                <i class="iconfont icon_icon_search tab-bar_search_icon" onClick={this.handleSearch} />
              ),
            }}
            onInput={this.handleInput}
          />
          {/* <el-button
            class="margin-left-5"
            type="primary"
            onClick={this.handleRecord}
          >收录博主</el-button> */}
        </el-col>
      </el-row>
    );
  },
});
