
import { defineComponent, ref, toRefs, reactive, watch } from 'vue';
import Tips from './tips.vue';
import { postCollectBlogger } from '@/modules/miaokuan-manage/blogger/api';
import { ICtInsCollectBloggerReq } from '@/modules/miaokuan-manage/blogger/api/type.d';
import { RECORD_STATUS_ENUMS } from '@/modules/miaokuan-manage/blogger/constant';

export default defineComponent({
  components: {
    Tips,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    poName: {
      type: String,
      default: '',
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { visible } = toRefs(props);
    const state = reactive({
      outerVisible: visible.value,
      tipsVisible: ref(false),
    });
    watch(
      visible,
      (newValue) => {
        state.outerVisible = newValue;
      },
      { immediate: true },
    );

    const tipType = ref<RECORD_STATUS_ENUMS>(RECORD_STATUS_ENUMS.SUCCESS);

    const close = () => {
      emit('close');
    };
    const closeAllDialog = () => {
      emit('close');
      state.tipsVisible = false;
    };

    const poId = ref<string|number>('');
    const handleRecord = async () => {
      const param:ICtInsCollectBloggerReq = {
        poName: props.poName,
      };
      const { data } = await postCollectBlogger(param);
      tipType.value = data.status || RECORD_STATUS_ENUMS.SUCCESS;
      poId.value = data.poId;
      state.tipsVisible = true;
    };
    return {
      ...toRefs(state),
      poId,
      tipType,
      close,
      handleRecord,
      closeAllDialog,
    };
  },
});
