// ins收录状态
export enum RECORD_STATUS_ENUMS {
  /* 已提交申请 */
  SUCCESS = '0',
  /* 已收录 */
  RECORDED = '1',
  /* 收录中 */
  RECORDING = '2',
  /* 绑定成功 */
  BIND_SUCCESS = '5',
}
