
import { defineComponent, reactive, toRefs, watch, PropType, inject } from 'vue';
import { useRouter } from 'vue-router';
import { RECORD_STATUS_ENUMS } from '@/modules/miaokuan-manage/blogger/constant';

export default defineComponent({
  props: {
    type: {
      type: String as PropType<RECORD_STATUS_ENUMS>,
      required: true,
    },
    title: {
      type: String,
      default: '提示',
    },
    'append-to-body': {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    poName: {
      type: String,
      default: '',
    },
    poId: {
      type: String,
      default: '',
    },
  },
  emits: ['update:visible', 'close'],
  setup(props, { emit }) {
    const router = useRouter();
    const { visible } = toRefs(props);

    const state = reactive({
      tipsVisible: visible.value,
    });
    watch(
      visible,
      (newValue) => {
        state.tipsVisible = newValue;
      },
      { immediate: true },
    );
    const close = () => {
      emit('close');
    };

    const viewBloggerDtl = () => {
      emit('close');
      const newPage = router.resolve({
        name: 'BloggerDetail',
        query: { poId: props.poId },
      });
      window.open(newPage.href);
    };
    const reloadPage = () => {
      emit('close');
      const newPage = router.resolve({
        name: 'BloggerList',
        query: { type: 'myIns' },
      });
      window.open(newPage.href);
      // window.location.reload();
    };

    return {
      ...toRefs(state),
      RECORD_STATUS_ENUMS,
      close,
      viewBloggerDtl,
      reloadPage,
    };
  },
});
