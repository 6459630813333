import {
  IS_MY_INS_ENUMS,
  SEARCH_BASE_SETTING_ENUM,
} from '@/modules/miaokuan-manage/common/constant';
import type{ ISearchConfigItem, SearchConfig } from '../type.d';

export const useSearchConfig = (handleSearch:()=>void) => {
  const getSearchConfig = (data:SearchConfig) => {
    const baseConfig: ISearchConfigItem[] = [
      {
        label: '',
        key: SEARCH_BASE_SETTING_ENUM.MY_INS,
        render: () => {
          return (
            data.isBindIns && (
              <el-checkbox
                v-model={data.params.myIns}
                true-label={IS_MY_INS_ENUMS.YES}
                false-label={IS_MY_INS_ENUMS.NO}
                label="我的ins"
                onChange={handleSearch}
              >
              </el-checkbox>
            )
          );
        },
      },
    ];
    const items: ISearchConfigItem[] = [];
    data.keys.forEach((v) => {
      const row = baseConfig.find(item => item.key === v);
      if (row) {
        items.push(row);
      }
    });
    return items.filter(v => v);
  };
  return {
    getSearchConfig,
  };
};
