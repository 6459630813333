import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

import * as Types from './type.d';

const server = DOMAIN_SYSTEM_ENUM.tiangong;

/**
 * 获取当前用户绑定的Instagram账户
 */
export function getBindInsInfo() {
  return http.get({
    url: '/moken-portal/web/v1/ct-ins/find-my-instagram-account',
    server,
  });
}

/**
 * 获取博主列表
 */
export function getBloggerList(data:Types.ICtInsSearchBloggerReq) {
  return http.post<Types.ICtInsSearchBloggerRes>({
    url: '/moken-portal/web/v1/ct-ins/search-blogger',
    server,
    loading: true,
    data,
  });
}

/**
 * 获取博主详情：https://yapi.ibaibu.com/project/1398/interface/api/105960
 */
export function getBloggerDetail(data:Types.ICtInsFindByPoIdReq) {
  return http.get<Types.ICtInsFindByPoIdRes>({
    url: '/moken-portal/web/v1/ct-ins/find-by-po-id',
    server,
    loading: true,
    params: data,
  });
}
/**
 * 获取爬取进度
 */
export function findCrawlingProgress() {
  return http.get<Types.ICtInsFindCrawlingProgressRes>({
    url: '/moken-portal/web/v1/ct-ins/find-crawling-progress',
    server,
  });
}

/**
 * 收录日志
 */
export function getCollectionLog() {
  return http.get<Types.ICtInsListCollectionLogRes>({
    url: '/moken-portal/web/v1/ct-ins/list-collection-log',
    server,
  });
}

/**
 * 新增收录Instagram博主
 */
export function postCollectBlogger(data:Types.ICtInsCollectBloggerReq) {
  return http.post<Types.ICtInsCollectBloggerRes>({
    url: '/moken-portal/web/v1/ct-ins/collect-blogger',
    server,
    data,
  });
}

/**
 * 博主帖子查询接口:https://yapi.ibaibu.com/project/1398/interface/api/105962
 */
export function getPostDetailList(data:Types.ICtInsListPostByPoIdReq) {
  return http.post<Types.ICtInsListPostByPoIdRes>({
    url: '/moken-portal/web/v1/ct-ins/list-post-by-poId',
    server,
    data,
  });
}
/**
 * 绑定我的Instagram
 */
export function postBindIns(data:Types.ICtInsBindMyInstagramReq) {
  return http.post<Types.ICtInsBindMyInstagramRes>({
    url: '/moken-portal/web/v1/ct-ins/bind-my-instagram',
    server,
    data,
  });
}
