
import { defineComponent, reactive, ref, toRefs, watch } from 'vue';
import Tips from './tips.vue';
import { RECORD_STATUS_ENUMS } from '@/modules/miaokuan-manage/blogger/constant';
import { ICtInsBindMyInstagramReq } from '@/modules/miaokuan-manage/blogger/api/type.d';
import { postBindIns } from '@/modules/miaokuan-manage/blogger/api';
import { ElMessage } from 'element-plus';

export default defineComponent({
  components: {
    Tips,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    poName: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup(props, { emit }) {
    const { visible } = toRefs(props);
    const state = reactive({
      outerVisible: visible.value,
    });
    watch(visible, (newValue) => {
      state.outerVisible = newValue;
    });
    const tipsVisible = ref(false);
    const closeAllDialog = () => {
      emit('close');
      tipsVisible.value = false;
    };

    const tipType = ref<RECORD_STATUS_ENUMS>(RECORD_STATUS_ENUMS.BIND_SUCCESS);

    const handleBindAccount = async () => {
      const param:ICtInsBindMyInstagramReq = {
        poName: props.poName,
      };
      const { data } = await postBindIns(param);
      if (data) {
        tipType.value = RECORD_STATUS_ENUMS.BIND_SUCCESS;
        tipsVisible.value = true;
      } else {
        ElMessage.error('绑定账号满60天后才可以重新绑定！');
      }
    };
    return {
      ...toRefs(state),
      tipsVisible,
      tipType,
      closeAllDialog,
      handleBindAccount,
    };
  },
});
