
import { defineComponent, ref, nextTick, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { processRouterQueryParams } from '@/utils/';
import useBlogList from '../../composables/use-blog-list';
import { useSearchConfig } from '../../composables/use-search-config';
import { getDesignTaskOptions } from '@/modules/task-manage/design-task/api';
import { cloneDeep } from 'lodash-es';
import type {
  ISearchSettingConfig,
  ICustomParams,
  IAdvancedSearchConfig,
  IDefaultSearchConfig,
  ISearchKeys,
  // IGoodsItem,
} from '../../type';
import type { IGetDesignTaskOptionsRes } from '@/modules/task-manage/design-task/api/types';
import {
  IS_MY_INS_ENUMS,
  SEARCH_BASE_SETTING_ENUM,
  SUB_STATUS_ENUMS,
  SORT_TYPE_ENUM,
} from '@/modules/miaokuan-manage/common/constant';
// eslint-disable-next-line
import { findCrawlingProgress, getBindInsInfo, getCollectionLog } from '@/modules/miaokuan-manage/blogger/api';
import { ElMessage, ElForm } from 'element-plus';
import NumberRangeInput from '../../../common/components/number-range-input';
// import SelectImgDialog from '../../components/select-img-dialog/index.vue';
import BlogItem from '../../../common/components/blog-item/index.vue';
// import Pagination from '@/modules/miaokuan-manage/components/pagination/index.vue';
import TabBar from '../../components/tab-bar/index.vue';
import CancelSub from '@/modules/miaokuan-manage/common/components/cancel-sub/index.vue';
import RecordBlogger from '../../components/record-blogger/index.vue';
import { IMkSubscribeReq } from '@/modules/miaokuan-manage/sub/api/type.d';
import { postInsSubscribe } from '@/modules/miaokuan-manage/sub/api';
import IconArrowDefault from '@/assets/images/resource/i_arrow_default.svg';
import IconArrowDown from '@/assets/images/resource/i_arrow_down.svg';
import IconArrowTop from '@/assets/images/resource/i_arrow_top.svg';
import {
  ICtInsFindCrawlingProgressRes,
  ICtInsListCollectionLogRes,
  ICtInsSearchBloggerListItem,
} from '@/modules/miaokuan-manage/blogger/api/type.d';
// import dayjs from 'dayjs';
import ListPagination from '@/components/list-pagination/package/index.vue';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    ListPagination,
    BlogItem,
    // SelectImgDialog,
    NumberRangeInput,
    // Pagination,
    TabBar,
    CancelSub,
    RecordBlogger,
  },
  setup() {
    const route = useRoute();
    const goodsEl = ref<null|HTMLElement>(null);
    const defaultSearchEl = ref<InstanceType<typeof ElForm> | null>(null);

    // 默认搜索条件
    const defaultSearchConfig = ref<IDefaultSearchConfig>({
      params: {
        myIns: IS_MY_INS_ENUMS.NO,
        poName: '',
      },
    });

    // 列表查询
    const {
      handleCurrentSizeChange,
      defaultSearchKeys,
      searchType,
      baseParams,
      params,
      blogList,
      reload,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      sortList,
      sortConfig,
      handleChangeSort,
      initPagination,
      currentViewPage,
      pageSize,
      total,
    } = useBlogList(defaultSearchConfig);

    // 默认搜索条件
    defaultSearchConfig.value = {
      params: cloneDeep(baseParams),
    };

    // 缓存到本地的需展示搜索项key[]
    const localSaveSettingKeys = (keys: string[]) => {
      localStorage.setItem('style_search_keys', JSON.stringify(keys));
    };

    // 初始化页面需要展示的搜索项
    const initSettingConfig = () => {
      const keys: Array<ISearchKeys> = [
        SEARCH_BASE_SETTING_ENUM.MY_INS, // 我的ins
        SEARCH_BASE_SETTING_ENUM.PO_NAME, // 博主名称
      ];
      defaultSearchKeys.value = keys;
      localSaveSettingKeys(keys);
    };
    initSettingConfig();

    // 搜索项配置
    const searchSettingConfig = ref<ISearchSettingConfig>({
      visible: false,
    });
    const handleChangeSettingConfigShow = (visible: boolean) => {
      searchSettingConfig.value.visible = visible;
    };
    const handleConfirmSearchSetting = (keys: Array<ISearchKeys>) => {
      defaultSearchKeys.value = [...keys];
      localSaveSettingKeys(keys);
    };

    // 高级搜索
    const advancedSearchConfig = ref<IAdvancedSearchConfig>({
      visible: false,
      params: cloneDeep(baseParams),
    });
    const handleAdvancedSearchModalShow = () => {
      advancedSearchConfig.value.visible = true;
    };
    const handleConfirmAdvancedSearch = (data: ICustomParams) => {
      searchType.value = 'advanced';
      advancedSearchConfig.value.params = data;
      defaultSearchConfig.value.params = cloneDeep(baseParams);
      params.value = cloneDeep(data);
      reload();
    };

    // 列表查询
    const handleSearch = async () => {
      try {
        await defaultSearchEl.value?.validate();
        searchType.value = 'default';
        advancedSearchConfig.value.params = cloneDeep(baseParams);
        params.value = cloneDeep(defaultSearchConfig.value.params);
        reload();
      } catch (error) {
        console.log(error);
      }
    };

    const handleReset = () => {
      searchType.value = 'default';
      advancedSearchConfig.value.params = cloneDeep(baseParams);
      defaultSearchConfig.value.params = cloneDeep(baseParams);
      params.value = cloneDeep(baseParams);
      reload();
    };

    // 字典和搜索项el
    const { getSearchConfig } = useSearchConfig(handleSearch);

    // 收录博主相关
    const recordConfig = ref<{visible:boolean;data:any;}>(
      { visible: false, data: [] },
    );
    const openRecordDialog = () => {
      recordConfig.value.visible = true;
    };
    const handleRecordBlogger = () => {

    };

    // 打开取消订阅弹框
    const cancelSubConfig = ref<{data:any;visible:boolean;}>(
      { visible: false, data: {} },
    );
    const openCancelSub = (row:ICtInsSearchBloggerListItem) => {
      cancelSubConfig.value.visible = true;
      cancelSubConfig.value.data = row;
    };

    // 订阅｜取消订阅
    const handleSubToggle = async (
      isSubscribe:SUB_STATUS_ENUMS = SUB_STATUS_ENUMS.SUB,
      row:ICtInsSearchBloggerListItem) => {
      if (row) {
        const param:IMkSubscribeReq = {
          poId: row?.poId,
          isSubscribe,
        };
        await postInsSubscribe(param);
        if (isSubscribe === SUB_STATUS_ENUMS.CANCEL) {
          cancelSubConfig.value.visible = false;
        }
        row.alreadySubscribe = isSubscribe === SUB_STATUS_ENUMS.SUB;
        const msg = isSubscribe === SUB_STATUS_ENUMS.SUB ? '订阅成功' : '取消订阅成功';
        ElMessage.success(msg);
      }
    };

    // 取消订阅弹窗-确定
    const handleConfirmCancel = (
      isSubscribe:SUB_STATUS_ENUMS = SUB_STATUS_ENUMS.SUB,
      row:ICtInsSearchBloggerListItem,
    ) => {
      trackingClick({
        eventName: '取消订阅弹窗-确定',
        targetId: row.poId,
      });
      handleSubToggle(isSubscribe, row);
    };

    // 选图-设计任务
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();

    // 是否绑定ins账号
    const isBindIns = ref<boolean>(true);

    // 查询账号明细
    const accountInfo = ref<ICtInsFindCrawlingProgressRes>({});
    // eslint-disable-next-line
    const getAccountInfo = async () => {
      const { data } = await findCrawlingProgress();
      accountInfo.value = data;
    };

    // 查询收录日志
    const recordsList = ref<ICtInsListCollectionLogRes>([]);

    // eslint-disable-next-line
    const getRecordList = async () => {
      const { data } = await getCollectionLog();
      recordsList.value = data || [];
    };

    const stepPercent = (row:ICtInsFindCrawlingProgressRes) => {
      const { numberOfCrawling, followPoCnt } = row;
      if (!numberOfCrawling || !followPoCnt || Number(followPoCnt) === 0) {
        return 0;
      }
      return Math.round((Number(numberOfCrawling) * 100) / Number(followPoCnt));
    };

    const init = async () => {
      await nextTick();
      // url 获取请求参数
      const urlParams = processRouterQueryParams(params.value);
      urlParams.pageNum = 0;
      params.value = urlParams;
      initPagination(goodsEl.value!);
      // 查询当前用户是否绑定ins，如果绑定，显示我的ins勾选项
      // const { data } = await getBindInsInfo();
      // isBindIns.value = !!data;
      // if (data && route.query.type) {
      //   defaultSearchConfig.value.params.myIns = IS_MY_INS_ENUMS.YES;
      // } else {
      //   defaultSearchConfig.value.params.myIns = IS_MY_INS_ENUMS.NO;
      // }
      // if (data) {
      //   getAccountInfo();
      // }
      // getRecordList();
      reload();
    };

    watch(() => route.query.type, () => {
      init();
    });

    onMounted(() => {
      init();
    });

    return {
      isBindIns,
      handleCurrentSizeChange,
      total,
      currentViewPage,
      pageSize,
      goodsEl,
      handleReset,
      defaultSearchEl,
      handleAdvancedSearchModalShow,
      defaultSearchKeys,
      handleSearch,
      defaultSearchConfig,
      advancedSearchConfig,
      handleChangeSort,
      sortList,
      sortConfig,
      getSearchConfig,
      handleConfirmAdvancedSearch,
      handleChangeSettingConfigShow,
      handleConfirmSearchSetting,
      searchSettingConfig,
      designTaskOptions,
      blogList,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      params,
      cancelSubConfig,
      handleSubToggle,
      openCancelSub,
      recordConfig,
      openRecordDialog,
      handleRecordBlogger,
      useSearchConfig,
      recordsList,
      accountInfo,
      stepPercent,
      handleConfirmCancel,
    };
  },
  render() {
    return (
      <div>
        <main class="container">
          <tab-bar
            v-model={[this.defaultSearchConfig.params.poName, 'poName']}
            onSearch={this.handleSearch}
            onRecord={this.openRecordDialog}
          />
          {/* <div class="conditions-container">
            <el-form
              label-width=""
              label-position="left"
              model={this.defaultSearchConfig.params}
              ref="defaultSearchEl"
            >
              <el-row
                class="flex flex-justify-between"
                gutter={20}
              >
                {this.getSearchConfig({
                  keys: this.defaultSearchKeys,
                  params: this.defaultSearchConfig.params,
                  isBindIns: this.isBindIns,
                }).map(item => (
                  <el-col
                    span={7}
                    key={item.label}
                    style="height:32px"
                  >
                    <el-form-item
                      label={item.label}
                      prop={item.key}
                      rules={item.rules || []}
                    >
                      {item.render()}
                    </el-form-item>
                  </el-col>
                ))}
                <el-col v-show={this.defaultSearchConfig.params.myIns === IS_MY_INS_ENUMS.NO} span={3}>
                  <el-tooltip
                    placement="bottom"
                    v-slots={{
                      content: () => (
                        <div class="records-container">
                          <table>
                            {this.recordsList.map(record => (
                              <tr>
                                <td>
                                <span class="icon-circle"></span>
                                  <span class="name-text" title={record.poName}>
                                    {record.poName}
                                  </span>
                                </td>
                                <td>{dayjs(record.dateTime).format('YYYY-MM-DD')}</td>
                                <td class="success-desc">{record.content}</td>
                              </tr>
                            ))}
                          </table>
                          <span v-show={!this.recordsList.length}>暂无数据</span>
                        </div>
                      ),
                    }}>
                    <div style="text-align:right">
                      <el-button type="default" style="border:none">
                        收录博主日志
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                    </div>
                  </el-tooltip>
                </el-col>
                <el-col v-show={this.defaultSearchConfig.params.myIns === IS_MY_INS_ENUMS.YES} span={3}>
                  <el-tooltip
                    placement="bottom"
                    v-slots={{
                      content: () => (
                        <>
                          <div class="step_percent">
                            <p>关注博主获取进度</p>
                            <div class="margin-top-5">
                              <strong class="step_percent_text">
                                {`${this.stepPercent(this.accountInfo)}%`}
                              </strong>
                              <span v-show="this.accountInfo.followPoCnt!=='0'">
                                {`${this.accountInfo.numberOfCrawling}/${this.accountInfo.followPoCnt}`}
                              </span>
                            </div>
                            <div>
                              <el-progress
                                text-inside={false}
                                show-text={false}
                                stroke-width={5}
                                percentage={this.stepPercent(this.accountInfo)}
                                status={this.stepPercent(this.accountInfo) === 100 ? 'success' : ''}
                              />
                            </div>
                          </div>
                          <div class="connect-time">
                            <span class="blue-block"/>
                            <p>账号关联:</p>
                            <p>{this.accountInfo.days}天</p>
                          </div>
                          <span>关联时长要求60天</span>
                        </>
                      ),
                    }}>
                    <div style="text-align:right">
                      <el-button type="default" style="border:none">
                        账号明细
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                    </div>
                  </el-tooltip>
                </el-col>
              </el-row>
            </el-form>
          </div> */}
          <div class="sorting-content">
            {this.sortList.map(item => (
              <div
                key={item.value}
                class="item"
                onClick={() => this.handleChangeSort(item.value)}
              >
                <div>{item.label}</div>
                <div class="arrow">
                  <img src={IconArrowDefault} class="arrow_icon" />
                  {this.sortConfig.prop === item.value
                  && this.sortConfig.order === SORT_TYPE_ENUM.TOP
                  && <img src={IconArrowTop} class="arrow_icon" />
                  }
                  {this.sortConfig.prop === item.value
                  && this.sortConfig.order === SORT_TYPE_ENUM.DOWN
                  && <img src={IconArrowDown} class="arrow_icon" />
                  }
                </div>
              </div>
            ))}
          </div>
          <section>
            <div
              class="goods-container"
              ref="goodsEl"
              v-infinite-scroll={this.loadMore}
              infinite-scroll-distance={50}
              infinite-scroll-disabled={this.listDisabled}
              infinite-scroll-immediate={false}
            >
              {this.blogList.map((v, i) => (
                <blog-item
                  key={i}
                  data={v}
                  onSubToggle={this.handleSubToggle}
                  onOpenCancel={this.openCancelSub}
                />
              ))}
            </div>
            {this.isEmpty ? (
              <empty />
            ) : (
              <div class="load-other">
                {this.listLoading && (
                  <p><i class="el-icon-loading"></i> 加载中...</p>
                )}
                {this.finish && (
                  <p>已经到底了</p>
                )}
              </div>
            )}
            <list-pagination
              total={this.total}
              current={this.currentViewPage}
              pageSize={this.pageSize}
              onCurrentChange={this.handleCurrentSizeChange}
            />
          </section>
        </main>
        <record-blogger
          v-model={[this.recordConfig.visible, 'visible']}
          days={this.accountInfo.days}
        />
        <cancel-sub
          v-model={[this.cancelSubConfig.visible, 'visible']}
          onSubCancel={() => {
            this.handleConfirmCancel(SUB_STATUS_ENUMS.CANCEL, this.cancelSubConfig.data);
          }
          }
        />
      </div>
    );
  },
});
