import { ref, computed, nextTick, Ref } from 'vue';
import { getBloggerList } from '@/modules/miaokuan-manage/blogger/api';
import { cloneDeep } from 'lodash-es';
import {
  IGoodsItem,
  ICustomParams,
  // IParams,
  ISortParams,
  ISearchKeys,
  IDefaultSearchConfig,
} from '../type.d';
import {
  MK_SORT_WORD_LIST,
  SORT_TYPE_ENUM,
  SORT_WORD_ENUM,
  IS_MY_INS_ENUMS,
} from '@/modules/miaokuan-manage/common/constant';
import { usePagination } from './use-blog-pagination';
import { trackingExposure } from '@/utils/tracking';
/* 支持 列表查询 */

export default (defaultSearchConfig:Ref<IDefaultSearchConfig>) => {
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination();
  const defaultSearchKeys = ref<Array<ISearchKeys>>([]);
  const sortConfig = ref<ISortParams>({
    prop: SORT_WORD_ENUM.LATELY,
    order: SORT_TYPE_ENUM.DOWN,
  });
  const total = ref(0);
  const baseParams: ICustomParams = {
    myIns: IS_MY_INS_ENUMS.NO,
    poName: '',
  };
  const params = ref<ICustomParams>(cloneDeep(baseParams));
  const searchType = ref<'default'|'advanced'>('default');
  const finish = ref(false);
  const blogList = ref<IGoodsItem[]>([]);
  const filterParams = (row: any) => {
    const obj: any = {};
    const keys = Object.keys(row);
    keys.forEach((key) => {
      if (defaultSearchKeys.value.find(v => key === v)) {
        obj[key] = row[key];
      }
    });
    return obj;
  };
  const formatParams = (row: ICustomParams) => {
    if (searchType.value === 'default') {
      row = filterParams(row);
    }
    return { ...row };
  };
  const getList = async (current: number) => {
    try {
      const res = await getBloggerList({
        ...formatParams(cloneDeep(params.value)),
        myIns: defaultSearchConfig.value.params.myIns,
        pageNum: current,
        pageSize: pageSize.value,
        ...sortConfig.value,
      });
      const newList = res.data?.list || [];
      blogList.value.push(...newList);
      trackingExposure(newList, 'poId', {
        eventName: '博主曝光量',
      });
      total.value = Number(res.data.total);
      if (Number(res.data.total) <= reqPageIndex.value * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      return Promise.reject();
    }
  };
  const reload = () => {
    reqPageIndex.value = 1;
    blogList.value = [];
    getList(reqPageIndex.value);
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    await getList(reqPageIndex.value);
    listLoading.value = false;
  };
  const handleChangeSort = (val: SORT_WORD_ENUM) => {
    sortConfig.value.prop = val;
    sortConfig.value.order = sortConfig.value.order === SORT_TYPE_ENUM.DOWN
      ? SORT_TYPE_ENUM.TOP : SORT_TYPE_ENUM.DOWN;
    reload();
  };
  const handleCurrentSizeChange = async (val: number) => {
    try {
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const reqArr = [];
        while (reqPageIndex.value < val) {
          reqPageIndex.value += 1;
          // eslint-disable-next-line no-await-in-loop
          reqArr.push(getBloggerList({
            ...formatParams(cloneDeep(params.value)),
            pageNum: reqPageIndex.value,
            pageSize: pageSize.value,
            ...sortConfig.value,
          }));
        }
        const resArr = await Promise.all(reqArr);
        const list:IGoodsItem[] = [];
        resArr.forEach((v, i) => {
          const { data } = v;
          list.push(...data.list);
          if (i === resArr.length - 1) {
            total.value = Number(data.total);
            if (Number(data.total) <= reqPageIndex.value * pageSize.value) {
              finish.value = true;
            } else {
              finish.value = false;
            }
          }
        });
        blogList.value.push(...list);
        trackingExposure(list, 'poId', {
          eventName: '博主曝光量',
        });
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
        //
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !blogList.value.length && !listLoading.value;
  });
  return {
    handleCurrentSizeChange,
    total,
    initPagination,
    pageSize,
    currentViewPage,
    defaultSearchKeys,
    searchType,
    baseParams,
    handleChangeSort,
    sortConfig,
    sortList: MK_SORT_WORD_LIST,
    params,
    blogList,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
  };
};
