
import { computed, defineComponent, PropType, reactive, ref, withKeys } from 'vue';
import { ElMessage } from 'element-plus';
import Tabs from '@/components/tabs/index';
import RelateInsDialog from '../popups/relate-ins-dialog.vue';
import RecordInsDialog from '../popups/record-ins-dialog.vue';
import IconInsGuide from '@/assets/blog/ins-guide.png';

type TabName ='add'|'my'
export default defineComponent({
  components: {
    Tabs,
    RelateInsDialog,
    RecordInsDialog,
  },
  emits: ['update:visible', 'sub-cancel'],
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    days: {
      type: String as PropType<string>,
      default: '61',
    },
  },
  setup(props, { emit }) {
    // const $router = useRouter();
    const currentTab = ref<TabName>('add');
    const poName = ref('');
    const tabList = ref<{label:string;value:TabName;}[]>([
      {
        label: '新增收录',
        value: 'add',
      },
      {
        label: '我的INS',
        value: 'my',
      },
    ]);
    const isShow = reactive({
      recordDialogVisible: false,
      relateDialogVisible: false,
    });
    const handleTabChange = () => {
      poName.value = '';
    };

    const handleClose = async () => {
      poName.value = '';
      currentTab.value = 'add';
      emit('update:visible', false);
    };

    const handleConfirm = async () => {
      try {
        if (!poName.value) {
          ElMessage.error('请输入您的Instagram账号');
        } else {
          isShow[currentTab.value === 'add' ? 'recordDialogVisible' : 'relateDialogVisible'] = true;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const bindInsDisabled = computed(() => {
      return currentTab.value === 'my' ? Number(props.days) < 60 : false;
    });

    return {
      handleClose,
      handleConfirm,
      currentTab,
      tabList,
      handleTabChange,
      poName,
      isShow,
      bindInsDisabled,
    };
  },
  render() {
    return (
      <el-dialog
        modelValue={this.visible}
        onClose={this.handleClose}
        top="25vh"
        center
        close-on-click-modal={false}
        close-on-press-escape={false}
        width="600px"
        custom-class="el-dialog-inner-scroll"
        v-slots={{
          title: () => (
          <tabs
          lineHeight={30}
          fontSize={16}
          border={false}
          config={this.tabList}
          v-model={this.currentTab}
          onChange={this.handleTabChange}
          />
          ),
          footer: () => (
            <el-row type="flex" justify="end" class="margin-bottom-20">
              <el-button size="small" onClick={this.handleClose}>取消</el-button>
              <div class="btn-confirm">
                <el-button
                  type={this.bindInsDisabled ? 'info' : 'primary'}
                  size="small"
                  disabled={this.bindInsDisabled}
                  onClick={this.handleConfirm}
                >确定</el-button>
                <span v-show={this.bindInsDisabled} class="red-text">
                  账号关联时长未达60天，暂时不能更换绑定账号
                </span>
              </div>
            </el-row>
          ),
        }}
      >
        <div v-show={this.currentTab === 'add'} class="addRecord">
          <el-input
            v-model={this.poName}
            placeholder="请输入新增收录INS博主的账号"
            class="input"
            input-style={{ paddingRight: '36px', height: '48px', lineHeight: '48px' }}
            onKeydown={withKeys(() => { this.handleConfirm(); }, ['enter'])}
          >
          </el-input>
          <el-popover placement="bottom" width={400} trigger="hover"
                      v-slots={{
                        reference: () => (
                          <p class="tips">*在哪里查看博主的Instagram账号？</p>
                        ),
                        default: () => (
                          <img
                            class="ins-guide"
                            src={IconInsGuide}
                          />
                        ),
                      }}
          >
          </el-popover>
          <record-ins-dialog
          poName={this.poName}
          visible={this.isShow.recordDialogVisible}
          onClose={() => { this.isShow.recordDialogVisible = false; }}
          />
        </div>
        <div v-show={this.currentTab === 'my'} class="record">
          <el-input
            v-model={this.poName}
            placeholder="请输入您的Instagram账号"
            class="input"
            input-style={{ paddingRight: '36px', height: '48px', lineHeight: '48px' }}
            onKeydown={withKeys(() => { this.handleConfirm(); }, ['enter'])}
          >
          </el-input>
          <el-popover placement="bottom" width={400} trigger="hover"
                      v-slots={{
                        reference: () => (
                          <p class="tips">*在哪里查看我的Instagram账号？</p>
                        ),
                        default: () => (
                          <img
                            class="ins-guide"
                            src={IconInsGuide}
                          />
                        ),
                      }}
          >
          </el-popover>
          <relate-ins-dialog
          poName={this.poName}
          visible={this.isShow.relateDialogVisible}
          onClose={() => { this.isShow.relateDialogVisible = false; }}
          />
        </div>
      </el-dialog>
    );
  },
});
